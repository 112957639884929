const en = {
  'welcome.note': 'Welcome to Cloud Forest Park',
  'name': 'Name',
  'all': 'All',
  'bird': 'Bird',
  'birds': 'Birds',
  'fish': 'fish',
  'fishes': 'fishes',
  'botanical.name': 'Botanical Name',
  'navigation.about': 'About',
  'navigation.tickets': 'Tickets',
  'navigation.gallery': 'Gallery',
  'navigation.our.services': 'Our Services',
  'ticket.booking': 'Ticket booking',
  'price.list': 'PRICE LIST',
  'park.timings': 'Timings',
  'ticket.price.child.below.3': 'Children below 3',
  'ticket.price.child.below.3.cost': 'Free',
  'ticket.price.child.3.t0.10': 'Children',
  'ticket.price.child.3.t0.10.cost': 'Rs.150',
  'ticket.price.adult.label': 'Adults',
  'ticket.price.adult.cost': 'Rs.200',
  'ticket.price.senior.citizen.label': 'Senior citizen',
  'ticket.price.senior.citizen.cost': 'Rs.75',
  'ticket.price.annual.pass.label': 'Annual pass',
  'ticket.price.annual.pass.cost': 'Rs.500',
  'ticket.price.drivers': 'Drivers',
  'ticket.price.differently.abled': 'Differently Abled',
  'free': 'Free',
  'price.list.free': 'Free Entry',
  'timing.weekdays.label': 'Weekdays',
  'timing.weekdays.time': '9am to 6pm',
  'timing.weekends.label': 'Weekends',
  'timing.weekends.time': '8am to 7pm',
  'timing.early.entry.label': 'Early Entry',
  'ticket.price.early.entry.cost': 'Rs.350',
  'timing.early.entry.time': '5am to 7am',
  'terms.and.conditions': 'Terms and Conditions',
  'terms.and.condition.1': 'Entry only with valid counter tickets or online tickets',
  'terms.and.condition.2': 'Plastic are prohibited inside park',
  'terms.and.condition.3': 'Admission rights reserved senior citizen valid photo ID proof required',
  'terms.and.condition.4': 'Ticket rates and park timings are subject to change without prior notice',
  'butterfly.glassy.tiger': 'Glassy tiger',
  'butterfly.parantica.aglea': 'Parantica aglea',
  'butterfly': 'Butterfly',
  "butterfly.crimson.rose": "Crimson rose",
  "butterfly.pachliopta.hector": "Pachliopta hector",
  "butterfly.common.rose": "Common rose",
  "butterfly.pachliopta.aritolochiae": "Pachliopta aritolochiae",
  "butterfly.tailed.jay": "Tailed jay",
  "butterfly.graphium.agamenon": "Graphium agamenon",
  "butterfly.common.bluebottle": "Common bluebottle",
  "butterfly.graphium.sarpedon": "Graphium sarpedon",
  "butterfly.blue.mormon": "Blue Mormon",
  "butterfly.papilio.polymnestor": "Papilio polymnestor",
  "butterfly.dark.blue.tiger": "Dark blue tiger",
  "butterfly.tirumala.septentrionis": "Tirumala septentrionis",
  "butterfly.common.sailer": "Common Sailer",
  "butterfly.neptis.hylas": "Neptis hylas",
  "butterfly.pioneer": "Pioneer",
  "butterfly.belenois.aurota": "Belenois aurota",
  "butterfly.red.pierrot": "Red Pierrot",
  "butterfly.talicada.nyseus": "Talicada nyseus",
  "butterfly.angled.castor": "Angled castor",
  "butterfly.ariadne.ariadne": "Ariadne ariadne",
  "butterfly.plain.tiger": "Plain tiger",
  "butterfly.danaus.chrysippus": "Danaus chrysippus",
  "butterfly.common.crow": "Common crow",
  "butterfly.euploea.core": "Euploea core",
  "butterfly.lime.butterfly": "Lime Butterfly",
  "butterfly.papilio.demoleus": "Papilio demoleus",
  "butterfly.great.orange.tip": "Great orange tip",
  "butterfly.habomia.glaucippe": "Habomia glaucippe",
  "butterfly.peacock.pansy": "Peacock pansy",
  "butterfly.junonia.almana": "Junonia almana",
  "butterfly.common.five.ring": "Common Five Ring",
  "butterfly.ypthima.baldus": "Ypthima baldus",
  "butterfly.striped.tiger": "Striped tiger",
  "butterfly.danaus.genutia": "Danaus genutia",
  "butterfly.lemon.pansy": "Lemon pansy",
  "butterfly.junonia.lemonias": "Junonia lemonias",
  "butterfly.yamfly": "Yamfly",
  "butterfly.loxura.atymnus": "Loxura atymnus",
  "butterfly.common.pierrot": "Common Pierrot",
  "butterfly.castalius.rosimon": "Castalius rosimon",
  "butterfly.common.indian.crow": "Common Indian Crow",
  "butterfly.malabar.tree.nymph": "Malabar Tree Nymph",
  "butterfly.idea.malabarica": "Idea malabarica",
  "butterfly.white.four.ring": "White Four Ring",
  "butterfly.ypthima.ceylonica": "Ypthima ceylonica",
  "butterfly.tamil.yeoman": "Tamil Yeoman",
  "butterfly.cirrochroa.thais": "Cirrochroa thais",
  "butterfly.tawny.coster": "Tawny coster",
  "butterfly.acraea.terpsicore": "Acraea terpsicore",
  "butterfly.common.lascar": "Common Lascar",
  "butterfly.pantoporia.honrdonia": "Pantoporia honrdonia",
  "butterfly.painted.lady": "Painted Lady",
  "butterfly.vanessa.cardui": "Vanessa cardui",
  "butterfly.indian.red.admiral": "Indian Red Admiral",
  "butterfly.vanessa.indica": "Vanessa indica",
  "butterfly.joker": "Joker",
  "butterfly.byblia.ilithiya": "Byblia ilithiya",
  "butterfly.commander": "Commander",
  "butterfly.moduza.procris": "Moduza procris",
  "butterfly.indian.fritillary": "Indian Fritillary",
  "butterfly.argynnis.hyperbius": "Argynnis hyperbius",
  "butterfly.tamil.lacewing": "Tamil Lacewing",
  "butterfly.cethosia.nietneri": "Cethosia nietneri",
  "butterfly.extra.lascar": "Extra lascar",
  "butterfly.pantoporia.sandaka": "Pantoporia sandaka",
  "butterfly.gaudy.baron": "Gaudy baron",
  "butterfly.euthalia.lubentin": "Euthalia lubentin",
  "butterfly.painted.courtesan": "Painted courtesan",
  "butterfly.euripus.consimilis": "Euripus consimilis",
  "butterfly.viceroy": "Viceroy",
  "butterfly.limenitis.archippus": "Limenitis archippus",
  "butterfly.common.buckeye": "Common Buckeye",
  "butterfly.junonia.coenia": "Junonia coenia",
  "butterfly.tamil.catseye": "Tamil Catseye",
  "butterfly.zipaetis.saitis": "Zipaetis saitis",
  "butterfly.great.eggfly": "Great Eggfly",
  "butterfly.hypolimnas.bolina": "Hypolimnas bolina",
  "butterfly.daniad.eggfly": "Daniad Eggfly",
  "butterfly.hypolimnas.misippus": "Hypolimnas misippus",
  "butterfly.chocolate.pansy": "Chocolate Pansy",
  "butterfly.junonia.iphita": "junonia iphita",
  "butterfly.blue.pansy": "Blue Pansy",
  "butterfly.junonia.orithya": "Junonia orithya",
  "butterfly.blue.tiger": "Blue Tiger",
  "butterfly.tirumala.limniace": "Tirumala limniace",
  "butterfly.black.veined.white": "Black veined White",
  "butterfly.aporia.crataegi": "Aporia crataegi",
  "butterfly.yellow.orange.tip": "Yellow Orange Tip",
  "butterfly.ixias.marianne": "Ixias marianne",
  "butterfly.white.orange.tip": "White Orange Tip",
  "butterfly.pioneer.white": "Pioneer White",
  "butterfly.black.swallowtail": "Black Swallowtail",
  "butterfly.papilio.polyxenes": "Papilio polyxenes",
  "butterfly.spicebush.swallowtail": "Spicebush Swallowtail",
  "butterfly.papilio.trolius": "Papilio trolius",
  "butterfly.common.mormon": "Common Mormon",
  "butterfly.papilio.polytes": "Papilio polytes",
  "butterfly.common.jay": "Common Jay",
  "butterfly.graphium.doson": "Graphium doson",
  "fruit orchid.sweet.orange": "Sweet orange",
  "fruit orchid.citrus.sinesis": "citrus sinesis",
  "fruit orchid.fig.fruit": "Fig fruit",
  "fruit orchid.ficus.carica": "ficus carica",
  "fruit orchid.jamun.fruit": "Jamun fruit",
  "fruit orchid.syzygium.cumini": "syzygium cumini",
  "fruit orchid.star.fruit": "Star fruit",
  "fruit orchid.averrhoa.carcombola": "averrhoa carcombola",
  "fruit orchid.water.apple": "water apple",
  "fruit orchid.syzygium.samaranvense": "syzygium samaranvense",
  "fruit orchid.mango": "Mango",
  "fruit orchid.mangifera.indica": "mangifera indica",
  "fruit orchid.mousami.(sweet.lemon)": "Mousami (sweet lemon)",
  "fruit orchid.citrus.limetta": "citrus limetta",
  "fruit orchid.guava": "guava",
  "fruit orchid.psidium.guajava": "psidium guajava",
  "fruit orchid.westindies.cherry": "westindies cherry",
  "fruit orchid.malpighia.emarginata": "malpighia emarginata",
  "fruit orchid.amla.(goose.berry)": "Amla (goose berry)",
  "fruit orchid.phyllanthus.emblica": "phyllanthus emblica",
  "fruit orchid.litchi": "Litchi",
  "fruit orchid.lithi.chinensis": "lithi chinensis",
  "fruit orchid.sapota": "sapota",
  "fruit orchid.manilkara.zapota": "manilkara zapota",
  "fruit orchid.rambutan": "Rambutan",
  "fruit orchid.nephelium.lappaceum": "nephelium lappaceum",
  "fruit orchid.papaya": "Papaya",
  "fruit orchid.carica.papaya": "Carica papaya",
  "fruit orchid.lemon": "Lemon",
  "fruit orchid.citrus.limon": "citrus limon",
  "fruit orchid.ber": "Ber",
  "fruit orchid.zizithus.mauritian": "zizithus Mauritian",
  "fruit orchid.red.banana": "Red banana",
  "fruit orchid.musa.acuminata.(red.dacca)": "musa acuminata (red dacca)",
  "fruit orchid.durian": "Durian",
  "fruit orchid.durio.zibethinus": "durio zibethinus",
  "fruit orchid.mulberry": "Mulberry",
  "fruit orchid.morus.abba": "morus abba",
  "fruit orchid.passion.fruit": "passion fruit",
  "fruit orchid.passiflora.edulis": "passiflora edulis",
  "fruit orchid.pomegranate": "Pomegranate",
  "fruit orchid.punica.granatum": "punica granatum",
  "fruit orchid.custard.apple": "Custard apple",
  "fruit orchid.annona.squamosa": "annona squamosa",
  "fruit orchid.jackfruit": "Jackfruit",
  "fruit orchid.artocarpus.heterophyllus": "artocarpus heterophyllus",
  "fruit orchid.grapes": "Grapes",
  "fruit orchid.vitis.vinifera": "Vitis Vinifera",
  "creepers.blue.pain.grass": "Blue Pain Grass",
  "creepers.panicum.antidotale": "Panicum antidotale",
  "creepers.maraga": "Maraga",
  "creepers.undefined": null,
  "creepers.dawana": "Dawana",
  "creepers.clenodendran": "Clenodendran",
  "creepers.terneria": "Terneria",
  "creepers.garlic.creeper": "Garlic Creeper",
  "creepers.honey.suckle": "Honey Suckle",
  "creepers.croton.red.(leaaf.plant": "Croton Red (Leaaf Plant",
  "creepers.cactus": "Cactus",
  "creepers.verigated.cactus": "Verigated Cactus",
  "creepers.coleus.plant": "Coleus Plant",
  "creepers.plumosa": "Plumosa",
  "creepers.salvinia": "Salvinia",
  "creepers.acalypha.indica": "Acalypha Indica",
  "creepers.golden.cypress": "Golden Cypress",
  "creepers.gerbera": "Gerbera",
  "creepers.gazania": "Gazania",
  "creepers.iposia": "Iposia",
  "medicinal plants.tecoma.rosea": "Tecoma Rosea",
  "medicinal plants.tabebuia.rosea": "Tabebuia rosea",
  "medicinal plants.basil": "Basil",
  "medicinal plants.ocimum.basilicum": "Ocimum basilicum",
  "medicinal plants.twin.flowerd.cassia": "Twin flowerd cassia",
  "medicinal plants.cassia.biflora": "Cassia Biflora",
  "medicinal plants.vinca.rosea": "Vinca Rosea",
  "medicinal plants.catharanthus.roseus": "Catharanthus roseus",
  "medicinal plants.flowering.plants": "Flowering Plants",
  "medicinal plants.undefined": null,
  "flowering plants.ant.tree": "Ant tree",
  "flowering plants.tripilaris.americana": "Tripilaris americana",
  "flowering plants.conocarpus": "Conocarpus",
  "flowering plants.undefined": null,
  "flowering plants.simarouba.glauca": "Simarouba Glauca",
  "flowering plants.magilam": "Magilam",
  "flowering plants.bauhinia.blackeana": "Bauhinia Blackeana",
  "flowering plants.pongamia.glabra": "Pongamia Glabra",
  "flowering plants.gulmohur": "Gulmohur",
  "flowering plants.arica.palm": "Arica Palm",
  "flowering plants.silver.oak": "Silver Oak",
  "flowering plants.michelia.champaca": "Michelia Champaca",
  "flowering plants.tabebuia.argentea": "Tabebuia Argentea",
  "flowering plants.anthurium": "Anthurium",
  "flowering plants.spathodia": "Spathodia",
  "flowering plants.calandrya": "Calandrya",
  "flowering plants.poincitya.white": "Poincitya White",
  "flowering plants.alocasia.black": "Alocasia Black",
  "flowering plants.verbena": "Verbena",
  "flowering plants.buddela": "Buddela",
  "flowering plants.holmskioldia.sanguinea": "Holmskioldia Sanguinea",
  "flowering plants.ixora(west.indies.jasmine": "Ixora(West Indies Jasmine",
  "flowering plants.daisy": "Daisy",
  "flowering plants.geranium": "Geranium",
  "flowering plants.hydrangea": "Hydrangea",
  "flowering plants.changing.rose": "Changing Rose",
  "flowering plants.petrea.volubilis": "Petrea Volubilis",
  "flowering plants.angelonia": "Angelonia",
  "flowering plants.orange.begonia": "Orange Begonia",
  "flowering plants.rondalasiya": "Rondalasiya",
  "flowering plants.lavender": "Lavender",
  "flowering plants.poinchetya": "Poinchetya",
  "flowering plants.tabebuia.avellendedae": "Tabebuia Avellendedae",
  "flowering plants.cupia": "Cupia",
  "flowering plants.paneer.(rose.plant)": "Paneer (Rose Plant)",
  "flowering plants.table.rose": "Table Rose",
  "flowering plants.alternanthera": "Alternanthera",
  "flowering plants.kalvallai.(canna": "Kalvallai (Canna",
  "flowering plants.canna.big": "Canna Big",
  "flowering plants.jack.mensia": "Jack Mensia",
  "flowering plants.cotton.dwarf": "Cotton Dwarf",
  "flowering plants.hibiscus.pot": "Hibiscus Pot"
}

export default en
