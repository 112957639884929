import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA7dp7mTMDyN-4aZqoq71qiQjC3YuOvbBU",
  authDomain: "cloudforest-server.firebaseapp.com",
  projectId: "cloudforest-server",
  storageBucket: "cloudforest-server.appspot.com",
  messagingSenderId: "26643720815",
  appId: "1:26643720815:web:356b2cbe2dc1cdb6e07b9a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
