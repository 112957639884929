import React, { useState } from "react";
import Routes from "./route/Routes";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
// import MyComponent
import "./App.css";
import "./styles/index.scss";
import { ThemeProvider } from "@mui/material";
import dark from "./styles/theme/Dark";
import light from "./styles/theme/Light";

const history = createBrowserHistory();
// const primaryColor = "#0275d8";
// const primaryColor = "#fff";

function App() {
  const [curentTheme, setCurentTheme] = useState(() => {
    return localStorage.getItem("theme") || "light";
  });
  const theme =
    curentTheme === "light" ? light() : dark();

  return (
    <ThemeProvider theme={theme}>
      <Router {...{ history }}>
        <Routes setCurentTheme={setCurentTheme} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
