import { useEffect, useState } from "react";
import { Box, Grid, Tab, Dialog } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import "./index.scss";
import AddImageDialog from "../../pages/admin/dashboard/AddImageDialog";

interface Props {
  categories: any;
  orientation: "vertical" | "horizontal";
  edit?: Boolean;
}

const CategoryTabs = ({
  categories,
  orientation,
  edit,
}: Props): JSX.Element => {
  console.log("categories ::: ", categories);
  const [tab, setTab] = useState("1");
  const [imageData, setImageData] = useState<any>();
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [singleImageData, setSingleImageData] = useState<any>();

  const handleTabChange = (event: React.SyntheticEvent, currentTab: string) => {
    setTab(currentTab);
  };

  const handleCategoryTab = async (id: string, label: string) => {
    const imagesRef = collection(db, `categories/${id}/images`);
    onSnapshot(imagesRef, (snapshot) => {
      var arr: any[] = [];
      snapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id,
          categoryId: id,
          categoryName: label,
        });
      });
      console.log("imagefsdf ::: ", arr);
      setImageData(arr);
    });
  };
  const handleImageEdit = (data: any) => {
    setOpenImageDialog(!openImageDialog);
    setSingleImageData(data);
  };
  const handleImageDelete = async (id: any, categoryId: any) => {
    await deleteDoc(doc(db, `categories/${categoryId}/images`, id));
  };
  useEffect(() => {
    if (categories.length !== 0) {
      handleCategoryTab(categories[0].id, categories[0].label);
    }
  }, [categories]);
  return (
    <Box>
      <Grid container maxWidth="xl" justifyContent="center">
        <Grid container item xs={12}>
          <TabContext value={tab}>
            <Grid container spacing={3}>
              <Grid item xs={edit ? 3 : 12}>
                <TabList
                  orientation={orientation}
                  variant="scrollable"
                  onChange={handleTabChange}
                  sx={{
                    ...(edit && { borderRight: 1, borderColor: "divider" }),
                  }}
                >
                  {categories &&
                    categories.map((data: any, i: number) => (
                      <Tab
                        label={data.label!}
                        value={(i + 1).toString()}
                        key={i}
                        onClick={() => handleCategoryTab(data.id, data.label)}
                      />
                    ))}
                </TabList>
              </Grid>
              <Grid item xs={edit ? 9 : 12}>
                <Grid container item xs={12} spacing={2}>
                  {imageData &&
                    imageData.map((data: any, i: number) => (
                      <Grid item xs={12} md={6} lg={4} key={i}>
                        <div className="image-edit-card">
                          <img src={data.imageUrl} alt="" />
                          <div className="card-overlay">
                            <div className="image-details">
                              <p className="name">Name: {data.name}</p>
                              <p className="botanical-name">
                                Botanical Name: {data.botanicalName}
                              </p>
                            </div>
                            {edit && (
                              <div className="image-action">
                                <span onClick={() => handleImageEdit(data)}>
                                  <EditIcon />
                                </span>
                                <span
                                  onClick={() =>
                                    handleImageDelete(data.id, data.categoryId)
                                  }
                                >
                                  <DeleteIcon />
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </TabContext>
        </Grid>
      </Grid>
      {/* Add image dialog */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openImageDialog}
        onClose={handleImageEdit}
      >
        <AddImageDialog
          singleImageData={singleImageData}
          categories={categories}
          onClose={handleImageEdit}
        />
      </Dialog>
    </Box>
  );
};

export default CategoryTabs;
