import React, { useEffect, useState } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { formatNumber } from '../../constants/constants'

interface CounterProps {
  count: number
}
const Counter = (props: CounterProps) => {

  const [count, setCount] = useState(0)
  const [isVisibled, setIsVisibled] = useState(false)

  const counter = (minimum: number, maximum: number) => {
    const countIncrease = (count1: number) => {
      setCount(() => {
        if (count1 < maximum) {
          if (count1 < 100) setTimeout(() => countIncrease(count1 + 1), 10)
          else setTimeout(() => countIncrease(count1 + 10), 5)
        } else {
          return maximum
        }
        return count1
      })
    }
    countIncrease(minimum)
  }
  useEffect(() => {
    if (isVisibled) counter(0, props.count)
  }, [isVisibled, props.count])

  const handleVisbileChange = (isVisible: boolean) => {
    if (isVisibled !== isVisible && !isVisibled) {
      setIsVisibled(isVisible)
      // counter(0, props.count)
    }
  }

  return (
    <ReactVisibilitySensor onChange={handleVisbileChange}>
      <div>
        <h1 id='stats-number'>{formatNumber(count)}</h1>
      </div>
    </ReactVisibilitySensor>
  );

}
export default Counter
