import { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AccessibleForwardTwoTone,
  LocalTaxi,
  NaturePeople,
  Person,
  WbSunny,
  Work,
} from "@mui/icons-material";
import { Adults, EarlyEntery, Kids } from "../../media/svgs";

import "./Tickets.scss";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

const freeCategory = [
  {
    label: "ticket.price.drivers",
    cost: "free",
    icon: LocalTaxi,
  },
  {
    label: "ticket.price.differently.abled",
    cost: "free",
    icon: AccessibleForwardTwoTone,
  },
];

const timingDetails = [
  {
    icon: <WbSunny />,
    label: "timing.early.entry.label",
    timing: "timing.early.entry.time",
  },
  {
    icon: <NaturePeople />,
    label: "timing.weekends.label",
    timing: "timing.weekends.time",
  },
  {
    icon: <Work />,
    label: "timing.weekdays.label",
    timing: "timing.weekdays.time",
  },
];

const Tickets = () => {
  const { t } = useTranslation();
  const [childRate, setChildRate] = useState("");
  const [adultRate, setAdultRate] = useState("");
  const [earlyEntryRate, setEarlyEntryRate] = useState("");

  useEffect(() => {
    const unSub = onSnapshot(doc(db, "tickets", "rate"), (doc) => {
      setChildRate(doc.data()!.children);
      setAdultRate(doc.data()!.adult);
      setEarlyEntryRate(doc.data()!.earlyEntry);
    });
    return () => {
      unSub();
    };
  }, []);
  return (
    <div className="ticker-pricing-page">
      <div>
        <h1>{t("ticket.booking")}</h1>
        <h4
          style={{
            textAlign: "center",
          }}
        >
          {t("price.list")}
        </h4>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            item
            xs={6}
            sm={6}
            md={3}
          >
            <Paper className="price-item">
              <p className="icon">
                <Kids className="icon-svg" />
              </p>
              <p>Children</p>
              <p className="cost">{childRate}</p>
            </Paper>
          </Grid>
          <Grid
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            item
            xs={6}
            sm={6}
            md={3}
          >
            <Paper className="price-item">
              <p className="icon">
                <Person className="icon-svg" />
              </p>
              <p>Adults</p>
              <p className="cost">{adultRate}</p>
            </Paper>
          </Grid>
          <Grid
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            item
            xs={6}
            sm={6}
            md={3}
          >
            <Paper className="price-item">
              <p className="icon">
                <EarlyEntery className="icon-svg" />
              </p>
              <p>Early Entry</p>
              <p className="cost">{earlyEntryRate}</p>
            </Paper>
          </Grid>
        </Grid>
        <h4
          style={{
            textAlign: "center",
          }}
        >
          {t("park.timings")}
        </h4>
        <Grid container spacing={3} justifyContent="center">
          {timingDetails.map((time: any, index: number) => {
            return (
              <Grid
                key={`timing-${index}`}
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                item
                xs={6}
                sm={6}
                md={3}
              >
                <Paper className="timing-item">
                  <p className="icon">{time.icon}</p>
                  <p>{t(time.label)}</p>
                  <p className="time">{t(time.timing)}</p>
                </Paper>
              </Grid>
            );
          })}
        </Grid>

        <h4
          style={{
            textAlign: "center",
          }}
        >
          {t("price.list.free")}
        </h4>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          {freeCategory?.map((ticketPrice: any, index: number) => {
            const { icon: Icon } = ticketPrice;
            return (
              <Grid
                key={`price-${index}`}
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                item
                xs={6}
                sm={6}
                md={3}
              >
                <Paper className="price-item">
                  <p className="icon">
                    <Icon className="icon-svg" />
                  </p>
                  <p>{t(ticketPrice.label)}</p>
                  <p className="cost">{t(ticketPrice.cost)}</p>
                </Paper>
              </Grid>
            );
          })}
        </Grid>

        <h3>{t("terms.and.conditions")}</h3>
        <ol>
          {[0, 1, 2, 3].map((value: any, index: number) => {
            return (
              <li key={`term-${index}`}>
                {t(`terms.and.condition.${index + 1}`)}
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default Tickets;
