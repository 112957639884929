/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import { makeStyles, createStyles } from "@mui/styles";
// import ToggleButton from '../toggleButton/CustomImageToggleButton'
import { useTranslation } from "react-i18next";
import { Menu, Tooltip } from "@mui/material";
import { Settings } from "../../settings/Settings";
import { Link, useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles(() => {
  return {
    root: {
      position: "absolute",
      right: 0,
      display: "flex",
      alignItems: "center",
      padding: "0 1.5rem",
      // [theme.breakpoints.down("xs")]: {
      //   paddingRight: '0rem',
      // }
    },
    buttonBar: {
      // [theme.breakpoints.down("xs")]: {
      //   display: "none"
      // },
      margin: "10px",
      paddingLeft: "16px",
      right: 0,
      position: "relative",
      width: "100%",
      background: "transparent",
      display: "flex",
      alignItems: "center",
    },
  };
});

const navigations = [
  // { label: 'navigation.animals', url: '/animals' },
  // { label: 'navigation.birds', url: '/birds' },
  // { label: 'navigation.about', url: '/about' },
  { label: "navigation.gallery", url: "/gallery" },
  { label: "navigation.our.services", url: "/our-services" },
  // { label: 'navigation.tickets', url: '/tickets', action: 'primary' },
];

const AppBarCollapse = (props: any) => {
  const classes = useStyles({ test: "hello" });
  const { setCurentTheme } = props;
  const { i18n, t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [lang, setLang] = useState("en" as string);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory();

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNav = (link: string) => {
    history.push(link);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // function switchTheme(e: any) {
  //   setIsDarkMode(e.target.checked)
  //   if (e.target.checked) {
  //     localStorage.setItem('theme', 'dark');
  //     setCurentTheme('dark')
  //   }
  //   else {
  //     localStorage.setItem('theme', 'light');
  //     setCurentTheme('light')
  //   }
  // }
  useEffect(() => {
    if (i18n.language !== lang) {
      setLang(i18n.language);
    }
  }, [i18n, lang]);
  useEffect(() => {
    document.documentElement.addEventListener("click", () => {
      // document.documentElement.className = 'dark'
    });
    const currentTheme = localStorage.getItem("theme");

    if (currentTheme) {
      // document.documentElement.setAttribute('data-theme', currentTheme);
      if (currentTheme === "dark") {
        setIsDarkMode(true);
      }
    }
  }, []);

  // const getThemeImage = () => {
  //   if (!isDarkMode) {
  //     return <i className="fa fa-sun-o" aria-hidden="true"></i>
  //   }
  //   return <i className="fa fa-moon-o" aria-hidden="true"></i>
  // }

  const handleLangTggle = (value: any, event: any) => {
    setLang(value);
    i18n.changeLanguage(value);
    window.localStorage.setItem("lang", value);
    handleClose();
    return;
  };
  return (
    <div className={classes.root}>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex", justifyContent: "end" },
        }}
      >
        {navigations.map((page) => (
          <Button
            key={page.url}
            onClick={() => {
              handleNav(page.url);
              handleCloseNavMenu();
            }}
            sx={{ my: 0, display: "block" }}
            color="inherit"
          >
            {t(page.label)}
          </Button>
        ))}
      </Box>
      <Button
        style={{
          padding: ".5rem",
          // background: "#14b72a",
          color: "white",
          fontWeight: "bold",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
        variant="contained"
        color="success"
        onClick={() => {
          handleNav('/tickets')
          handleCloseNavMenu()
        }}
      >
        {t("navigation.tickets")}
      </Button>
      <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {navigations.map((page) => (
            <MenuItem
              key={page.url}
              onClick={() => {
                handleNav(page.url);
                handleCloseNavMenu();
              }}
            >
              <Typography color="MenuText" textAlign="center">
                {t(page.label)}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </div>
  );
};

export default AppBarCollapse;
