import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import './TopicContainer.scss'

const TopicConainer = (props: {
  imageSrc: string
  description: string
  title: string
  url?: string
}) => {
  const { imageSrc, description, title, url } = props
  return <div style={{
    overflow: 'hidden',
    position: 'relative',
  }}>
    <div className="topic-container" style={{
      backgroundImage: `url(${imageSrc})`,
      height: '70vh',
    }}>
      <div className="background-bgcolor">
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
          {url && <Button variant="contained" color="warning">
            <Link
              to={url}
              style={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              View Details
            </Link>
          </Button>}
        </div>

      </div>
    </div>
  </div>
}

export default TopicConainer
