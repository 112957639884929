import React from 'react'
import { Grid, Paper } from '@mui/material'
import './Footer.scss'
import { Email, Facebook, Instagram, LocationOn, Phone, WatchLater, WhatsApp } from '@mui/icons-material'


const socialMedia = [
  { label: 'Whatsapp', icon: <WhatsApp />, link: 'https://wa.me/917010050711?text=Hi%2C+I+Want+to+know+more+about+CloudForesPark' },
  { label: 'Instagram', icon: <Instagram />, link: 'https://www.instagram.com/cloudforestpark' },
  // { label: 'Facebook', icon: <Facebook />, link: '' },
]

const Footer = () => {
  return <Paper className="footer-details">
    <footer>
      {/* <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2062.8146689063155!2d78.6508697813087!3d12.577244854898774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3badab16abd16a8b%3A0xeb84efa97e7e8596!2sCloud%20Forest%20-%20Entertainment%20park!5e0!3m2!1sen!2sin!4v1631157363039!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{
            border: 0,
          }}
          allowFullScreen={true}
          loading="lazy"
          title="location"
        ></iframe>
      </div> */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="phonenumber">
            <a href="tel:+917010050711">
              <div>
                <Phone />
              </div>
              <div>
                +91 70100 50711
              </div>
            </a>
          </div>
          <div className="location">
            <a rel="noreferrer" href="https://goo.gl/maps/wD3y6uJeMZmwUamH6" target="_blank">
              <div>
                <LocationOn />
              </div>
              <div>
                Athanavoor, Mettukaniyur village,
                Yelagiri Hills, 635853
              </div>
            </a>
          </div>
          <div className="email">
            <a rel="noreferrer" href="mailto:info@cloudforest.com" target="_blank">
              <div>
                <Email />
              </div>
              <div>
                info@cloudforest.com
              </div>
            </a>
          </div>
          <div className="email">
            <a rel="noreferrer" href="#time" target="_blank">
              <div>
                <WatchLater />
              </div>
              <div>
                9AM to 7PM
              </div>
            </a>
          </div>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className="social">
            <ul>
              {socialMedia.map((social: any, index: number) => {
                return <li key={`social-${index}`}>
                  <a href={social.link} target="_blank" rel="noreferrer">
                    <div className="icon">
                      {social.icon}
                    </div>
                    <div className="label">
                      {social.label}
                    </div>
                  </a>
                </li>
              })}
            </ul>
          </div>
        </Grid>
      </Grid>
    </footer>
  </Paper>
}

export default Footer