import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

interface Props {
  onClose: any;
}

const TicketsDialog = ({ onClose }: Props): JSX.Element => {
  const [childRate, setChildRate] = useState("");
  const [adultRate, setAdultRate] = useState("");
  const [earlyEntryRate, setEarlyEntryRate] = useState("");

  useEffect(() => {
    const unSub = onSnapshot(doc(db, "tickets", "rate"), (doc) => {
      setChildRate(doc.data()!.children);
      setAdultRate(doc.data()!.adult);
      setEarlyEntryRate(doc.data()!.earlyEntry);
    });
    return () => {
      unSub();
    };
  }, []);

  const handleUpdateTicketRate = async () => {
    try {
      await updateDoc(doc(db, "tickets", "rate"), {
        children: childRate,
        adult: adultRate,
        earlyEntry: earlyEntryRate,
      });
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Edit Ticket Rates{" "}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme: any) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" px={2} py={4}>
          <TextField
            type="number"
            variant="standard"
            id="child-rate"
            label="Children"
            value={childRate}
            onChange={(e: any) => setChildRate(e.target.value)}
            sx={{ mb: 3 }}
            fullWidth
          />
          <TextField
            type="number"
            variant="standard"
            id="adult-rate"
            label="Adult"
            value={adultRate}
            onChange={(e: any) => setAdultRate(e.target.value)}
            sx={{ mb: 3 }}
          />
          <TextField
            type="number"
            variant="standard"
            id="early-entry-rate"
            label="Early Entry"
            value={earlyEntryRate}
            onChange={(e: any) => setEarlyEntryRate(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateTicketRate}
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default TicketsDialog;
