import { Delete, Edit } from "@mui/icons-material";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
  IconButton,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useMemo, useState } from "react";

interface imageListProps {
  name?: string;
  label: string;
  botanicalName?: string;
  imageData?: string;
  category?: string;
  id: any;
}

interface GalleryProps {
  imageList: imageListProps[];
  showCategory?: boolean;
  onEdit?: (event: any, item: imageListProps) => void;
  onDelete?: (event: any, item: imageListProps) => void;
  categoryAlign?: "start" | "center" | "end";
}

const BotanicalGallery = (props: GalleryProps) => {
  const {
    imageList = [],
    showCategory = false,
    onEdit,
    onDelete,
    categoryAlign = "start",
  } = props;
  const [filter, setFilter] = useState("");
  const matches = useMediaQuery("(min-width:600px)");
  const categories = useMemo(() => {
    const result = {
      "": 0,
    } as any;
    imageList?.forEach((item: imageListProps) => {
      const category = item.category || "";
      if (!result[category]) {
        result[category] = 0;
      }
      result[""] = result[category] + 1;
      result[category] = result[category] + 1;
    });
    return result;
  }, [imageList]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setFilter(newValue);
  };
  return (
    <div>
      {showCategory && (
        <>
          <Box
            justifyContent={categoryAlign}
            display="flex"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs
              // orientation="vertical"
              variant="scrollable"
              value={filter}
              onChange={handleChange}
              aria-label="Category Tabs"
              indicatorColor="primary"
            >
              {Object.keys(categories)?.map((key: any) => {
                return (
                  <Tab
                    // color={key === filter ? "success" : "primary"}
                    label={key || "All"}
                    value={key}
                  />
                );
              })}
            </Tabs>
          </Box>
        </>
      )}
      <ImageList
        sx={{ width: "100%", maxWidth: 1300 }}
        cols={matches ? 3 : 1}
        rowHeight={undefined}
      >
        {imageList
          .filter((item: imageListProps) => {
            return !filter || item.category === filter;
          })
          .map((item: imageListProps) => (
            <ImageListItem key={item.name}>
              <img
                src={`${item.imageData}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.imageData}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.name}
                loading="lazy"
              />
              <ImageListItemBar
                position="bottom"
                title={`Name: ${item.name}`}
                subtitle={<p>{`Botanical Name:  ${item.botanicalName}`}</p>}
                actionIcon={
                  <>
                    {onEdit && (
                      <IconButton
                        onClick={(e: any) => onEdit(e, item)}
                        color="default"
                        style={{
                          color: "#fff",
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                    {onDelete && (
                      <IconButton
                        onClick={(e: any) => onDelete(e, item)}
                        color="default"
                        style={{
                          color: "#fff",
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </>
                }
              />
            </ImageListItem>
          ))}
      </ImageList>
    </div>
  );
};

export default BotanicalGallery;
