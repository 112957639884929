import { Button, Grid, TextField, Typography } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { auth } from "../../../firebase";
import { useHistory } from "react-router-dom";

const Login = (props: any) => {
  const history = useHistory();

  const { onLogin } = props;
  const [formValue, setFormValue] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    message: "",
  } as any);
  const handleSubmit = async () => {
    if (!formValue.username) {
      setErrors((prev: any) => ({ ...prev, username: "Username Required" }));
    }
    if (!formValue.password) {
      setErrors((prev: any) => ({ ...prev, password: "Password Required" }));
    }
    if (formValue.username && formValue.password) {
      try {
        const abc = await signInWithEmailAndPassword(
          auth,
          formValue.username,
          formValue.password
        );
        history.push("/admin");
      } catch (err) {
        setErrors({ message: "incorrect username or password" });
      }
    }
  };
  const handleChange = (key: string, value: any) => {
    setFormValue((prev: any) => ({ ...prev, [key]: value }));
    setErrors((prev: any) => ({ ...prev, [key]: "" }));
    return;
  };
  return (
    <div style={{ marginTop: "10rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Grid container item sm={6} md={3} justifyContent="center" spacing={4}>
          <h3>Login</h3>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center" }}>
              {errors.message}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="username"
              label="Username"
              variant="standard"
              color="secondary"
              required
              fullWidth
              value={formValue.username}
              helperText={errors.username}
              error={!!errors.username}
              onChange={(event: any) =>
                handleChange("username", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              variant="standard"
              label="Password"
              color="secondary"
              type="password"
              value={formValue.password}
              error={!!errors.password}
              helperText={errors.password}
              onChange={(event: any) =>
                handleChange("password", event.target.value)
              }
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ color: "white" }}
              color="secondary"
              variant="contained"
              onClick={handleSubmit}
              fullWidth
            >
              Login
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="text"
              onClick={handleSubmit}
              fullWidth
            >
              Forgot Password
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Login;
