import React, { useEffect } from 'react'
import {
  Switch,
  Route,
  // useRouteMatch
} from 'react-router-dom'
import CSVFileReader from '../components/covertCSV/CovertCSV';
import Footer from '../components/footer/Footer';
import Header from '../components/navBar/Header';
import About from '../pages/about/About';
import Dashboard from '../pages/admin/dashboard/Dashboard';
import AddImageForm from '../pages/admin/gallery/add-images/AddImageForm';
import AdminGallery from '../pages/admin/gallery/AdminGallery';
import Login from '../pages/admin/login/Login';
import GalleryPage from '../pages/gallery/GalleryPage';
import Home from '../pages/home/Home';
import OurServices from '../pages/services/OurServices';
import Tickets from '../pages/tickets/Tickets';
import { Settings } from '../settings/Settings'
import SecuredRoute from './SecuredRoute';

const Routes = (props: any) => {
  // let match = useRouteMatch();
  useEffect(() => {
    document.title = Settings.appHeaderName
  }, [])
  return (
    <>
      <Header { ...props } />
      <div className="app-body">
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/tickets" component={Tickets} />
          <Route path="/gallery" component={GalleryPage} />
          <Route path="/" exact={true} component={Home} />
          <Route path="/our-services" component={OurServices} />
          <Route path="/csv" exact={true} component={CSVFileReader} />
          <Route path="/admin/login" component={Login} />
          <SecuredRoute path="/admin/gallery/upload" component={AddImageForm} />
          <SecuredRoute path="/admin/gallery" component={AdminGallery} />
          {/* <Route path="/admin" exact component={Dashboard} /> */}
          <SecuredRoute path="/admin" component={Dashboard} />
          {/* <Redirect to="/" /> */}
        </Switch>
        <Footer />
      </div>
    </>
  )

}

export default Routes
