import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import "./CSVFileReader.scss";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_")
};

const CSVFileReader = () => {
  const [data, setData] = useState([])
  const [english, setEnglish] = useState({})
  const [tamil, setTamil] = useState({})
  const handleForce = (data: any, fileInfo: any) => {
    console.log(data, fileInfo)
    const en = {} as any
    const tn = {} as any
    const newData = data.map((item: any, index: number) => {
      const type = item.type?.toLowerCase()
      let name = item.name?.toLowerCase().replace(/ /gi, '.')
      name = `${type}.${name}`
      let botanicalName = item.botanical_name?.toLowerCase().replace(/ /gi, '.')
      botanicalName = `${type}.${botanicalName}`?.trim()
      en[name] = item.name?.trim()
      en[botanicalName] = item.botanical_name?.trim()
      tn[name] = item.Tamil?.trim() || item.name?.trim()
      // en[botanicalName] = item.botanical_name
      return {
        id: index + 1,
        name,
        type,
        botanicalName
      }
    })
    setData(newData)
    setEnglish(en)
    setTamil(tn)
  };
  return (
    <div className="csv-container">
      <CSVReader
        cssClass="react-csv-input"
        label="Select CSV with secret Death Star statistics"
        onFileLoaded={handleForce}
        parserOptions={papaparseOptions}
      />
      <p>and then open the console</p>
      <pre>{JSON.stringify(data, undefined, '  ')}</pre>
      English
      <pre>{JSON.stringify(english, undefined, '  ')}</pre>
      Tamil
      <pre>{JSON.stringify(tamil, undefined, '  ')}</pre>
    </div>
  )
}

export default CSVFileReader
