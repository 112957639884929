import {
  AppBar,
  Avatar,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Settings } from "../../settings/Settings";
import { ReactComponent as AppLogo } from "../../media/images/AppBarLogo.svg";
import MobilePng from "../../media/images/favicon.png";
import "./Header.scss";
import AppBarCollapse from "./AppBarCollapse";

const Header: React.FC = (props: any) => {
  const { setCurentTheme } = props;

  return (
    <div className="topnav" id="myTopnav">
      <AppBar position="fixed" color="default">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link
              to="/"
              style={{
                textDecoration: "none",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <AppLogo className="app-logo" />
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              >
                <Avatar alt={Settings.appHeaderName} src={MobilePng} />
              </Typography>
            </Link>
            <AppBarCollapse setCurentTheme={setCurentTheme} />

            {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Header;
