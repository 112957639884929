import { useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

interface Props {
  onClose: any;
}

const AddCategoryDialog = ({ onClose }: Props): JSX.Element => {
  const [category, setCategory] = useState("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<null | string>("");

  const handleImage = (event: any) => {
    const selectedImage = event.target.files[0];
    console.log(selectedImage.name);
    try {
      const storageRef = ref(storage, `category/${selectedImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedImage);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setIsUploading(true);
          console.log(
            "uploading ::: ",
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err: any) => {
          console.log("upload err ::: ", err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImageUrl(downloadURL);
            setIsUploading(false);
          });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveCategory = async () => {
    try {
      const id = uuid().split("-").join("");
      await setDoc(
        doc(db, "categories", id),
        {
          name: category,
          imageUrl: imageUrl,
          date: Timestamp.now(),
        },
        { merge: true }
      );
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Add Category
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme: any) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            {isUploading ? (
              <div className="upload-loader">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <>
                {imageUrl ? (
                  <div className="uploaded-image">
                    <span onClick={() => setImageUrl(null)}>🗙</span>
                    <img src={imageUrl} alt="" />
                  </div>
                ) : (
                  <label>
                    <Box className="upload-img-btn">
                      <Button
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={handleImage}
                        />
                        <PhotoCameraIcon />
                      </Button>
                    </Box>
                  </label>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Box px={2} py={4}>
              <TextField
                variant="standard"
                id="category-name"
                label="Add a category"
                value={category}
                onChange={(e: any) => setCategory(e.target.value)}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveCategory}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AddCategoryDialog;
