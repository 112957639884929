import { Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import AddImageFormWired from "./AddImageFormWired";
import ImageInfo from "./ImageInfo";
import qs from 'qs';

const getTimeStamp = () => new Date().getUTCMilliseconds();

function AddImageForm(props: any) {
  const { handleAddImagesToGallery, galleryList } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const queries = qs.parse(history.location.search?.slice(1) || '')
  const initValues = { id: getTimeStamp(), category: queries.type || '' };
  const [formValues, setFormValues] = useState([initValues] as any);

  const categories = useMemo(() => {
    const newCategories = {} as any;
    galleryList?.forEach((item: any) => {
      if (!newCategories[item.category]) newCategories[item.category] = 0;
      newCategories[item.category] = newCategories[item.category] + 1;
    });
    return newCategories;
  }, [galleryList]);

  const setFormDetail = (index: any, values: any) => {
    setFormValues((prev: any) => {
      const newData = [...prev];
      newData[index] = values;
      return newData;
    });
  };
  const handleAddForm = () => {
    setFormValues((prev: any) => {
      const newData = [...prev, { ...initValues, id: getTimeStamp() }];
      return newData;
    });
  };
  const handleDelete = (index: number) => {
    setFormValues((prev: any) => {
      const leftData = [...prev].slice(0, index);
      const rightData = [...prev].slice(index + 1);
      return [...leftData, ...rightData];
    });
  };
  const requestImageBody = (value: any, index: number) => {
    const {
      name,
      botanicalName,
      hi,
      tn,
      s3_image_url,
      category = "Butterfly",
    } = value;
    const error = {} as any;
    if (!s3_image_url) {
      error.s3_image_url = "Required";
    }
    if (!botanicalName) {
      error.botanicalName = "Required";
    }
    if (!name) {
      error.name = "Required";
    }
    if (!category) {
      error.category = "Required";
    }
    setErrors((prev: any) => {
      if (Object.keys(error).length > 0) {
        return [...prev, error];
      }
      return prev;
    });
    return {
      category,
      name,
      botanicalName,
      languages: [
        { language: "tn", name: tn },
        { language: "hi", name: hi },
      ],
      image: s3_image_url,
    };
  };
  const handleSubmit = () => {
    setIsLoading(true);
    const requestFormValues = formValues.map(requestImageBody);
    if (!errors.length) handleAddImagesToGallery(requestFormValues);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          marginTop: "6rem",
          padding: "2rem",
          maxWidth: "1366px",
        }}
      >
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            color="error"
            onClick={() => {
              history.push("/admin/gallery"+history.location.search);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            style={{
              color: "white",
            }}
            color="success"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
        {formValues.map((formValue: any, index: number) => {
          return (
            <div key={`form-value-${formValue.id}`}>
              <ImageInfo
                setFormDetail={(data: any) => setFormDetail(index, data)}
                currentValue={formValue}
                index={index}
                onDelete={formValues.length > 1 ? handleDelete : undefined}
                categories={Object.keys(categories)}
              />
            </div>
          );
        })}
        <Button
          color="success"
          variant="contained"
          style={{
            color: "white",
          }}
          onClick={handleAddForm}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

export default AddImageFormWired(AddImageForm);
