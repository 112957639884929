/* eslint-disable react-hooks/exhaustive-deps */
// import { useHistory } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import Actions from "../../redux/actions/product/gallery";
// import { useEffect } from "react";

const GalleryPageWired = (WrappedComponent: any) => {
  const NewComponent = (props: any) => {
    // const history = useHistory();
    // const dispatch = useDispatch();
    // const { galleryList } = useSelector(
    //   (state: any) => state?.products?.gallery || {}
    // );

    // useEffect(() => {
    //   dispatch(Actions.onLoadProducts());
    // }, []);

    // const onDeleteImage = (id: string) => {
    //   dispatch(
    //     Actions.onDeleteProduct(id, () => {
    //       dispatch(Actions.onLoadProducts());
    //     })
    //   );
    // };
    // const handleAddImagesToGallery = (values: any, callback?: any) => {
    //   dispatch(
    //     Actions.onAddProducts(values, () => {
    //       dispatch(Actions.onLoadProducts());
    //       if (callback) callback()
    //     })
    //   );
    // };
    // const handleUpdateImageOnGallery = (id: any, data: any, callback?: any) => {
    //   dispatch(
    //     Actions.onUpdateProduct(id, data, () => {
    //       dispatch(Actions.onLoadProducts());
    //       if (callback) callback()
    //     })
    //   );
    // };

    return (
      <WrappedComponent
        // {...props}
        // galleryList={galleryList}
        // onDeleteImage={onDeleteImage}
        // handleAddImagesToGallery={handleAddImagesToGallery}
        // handleUpdateImageOnGallery={handleUpdateImageOnGallery}
      />
    );
  };

  return NewComponent;
};

export default GalleryPageWired;
