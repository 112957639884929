import React from 'react'
// import Slides from '../../components/slide/Slides';
// import DeerImageSrc from '../../media/images/animals/deer.jpg'
// import TigerImageSrc from '../../media/images/animals/tiger.jpg'
// import CarlosSpitzerImageSrc from '../../media/images/animals/carlos-spitzer.jpg'
import Box from '@mui/material/Box';

import './Home.scss'
import OurAnimals from './OurAnimals';
// import AdvancedImageList from '../../components/image-collage/AdvancedImageList';
import WhatYouSee from './whatYouSee/WhatYouSee';
import FloadingAction from '../../components/floating-actions/FloadingAction';

// const listItemsRaw = [
//   {
//     title: '', position: 'left',
//     description: '',
//     image: HomeBackgoundImageSrc
//   },
//   { title: 'step 2', position: 'right', description: 'hello world', image: DeerImageSrc },
//   { title: 'step 3', position: 'left', description: 'hello world', image: TigerImageSrc },
//   { title: 'step 4', position: 'right', description: 'hello world', image: CarlosSpitzerImageSrc },
// ];

const Home: React.FC = (props: any) => {
  console.log(props)
  return (
    <div className="home-page-wrpper">
      <FloadingAction />
      <div>
        <div className="home-image-wrapper">
          <div className="slide-description">
            <div className="home-page-text-wrapper">
              <h4>Welcome to </h4>
              <h2>Cloud Forest Park</h2>
              <p>Lose yourself in nature and find peace</p>
            </div>
          </div>
        </div>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <OurAnimals />
      </Box>
      <Box>
        <WhatYouSee />
      </Box>
    </div>
  )
}

export default Home
