import { useState, useEffect } from "react";
import { Box, Button, Container, Dialog } from "@mui/material";
import AddCategoryDialog from "./AddCategoryDialog";
import TicketsDialog from "./TicketsDialog";
import AddImageDialog from "./AddImageDialog";
import "./index.scss";
import CategoryTabs from "../../../components/category-tabs/CategoryTabs";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";

const Dashboard = () => {
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openTicketDialog, setOpenTicketDialog] = useState(false);
  const [categories, setCategories] = useState<any>([]);

  const handleCategoryDialog = () => {
    setOpenCategoryDialog(!openCategoryDialog);
  };
  const handleImageDialog = () => {
    setOpenImageDialog(!openImageDialog);
  };

  const handleTicketDialog = () => {
    setOpenTicketDialog(!openTicketDialog);
  };

  const getData = () => {
    var arr: any[] = [];
    onSnapshot(collection(db, "categories"), (snapshot) => {
      snapshot.forEach((doc) => {
        arr.push({ id: doc.id, label: doc.data().name });
      });
      setCategories(arr);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Container sx={{ my: 14 }}>
        <Box display="flex" justifyContent="center" gap={3}>
          <Button
            variant="contained"
            color="success"
            onClick={handleCategoryDialog}
          >
            Add Category
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleImageDialog}
          >
            Add Image
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleTicketDialog}
          >
            Edit Ticket Rates
          </Button>
        </Box>
        <Box mt={8}>
          <CategoryTabs categories={categories} orientation="vertical" edit />
        </Box>
      </Container>

      {/* Add category dialog */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCategoryDialog}
        onClose={handleCategoryDialog}
      >
        <AddCategoryDialog onClose={handleCategoryDialog} />
      </Dialog>

      {/* Add image dialog */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openImageDialog}
        onClose={handleImageDialog}
      >
        <AddImageDialog categories={categories} onClose={handleImageDialog} />
      </Dialog>

      {/* Ticket Dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openTicketDialog}
        onClose={handleTicketDialog}
      >
        <TicketsDialog onClose={handleTicketDialog} />
      </Dialog>
    </>
  );
};

export default Dashboard;
