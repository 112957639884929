const ACTION = 'ADMIN/GALLERY'
export const RESET = `${ACTION}/RESET`
export const GET_PRODUCTS_ALL = `${ACTION}/GET_PRODUCTS_ALL`
export const GET_SESSION = `${ACTION}/GET_SESSION`
export const ADD_PRODUCTS = `${ACTION}/ADD_PRODUCTS`
export const UPDATE_PRODUCT = `${ACTION}/UPDATE_PRODUCT`
export const DELETE_PRODUCT = `${ACTION}/DELETE_PRODUCT`
export const DELETE_PRODUCTS = `${ACTION}/DELETE_PRODUCTS`

const reset = () => ({
  type: RESET,
})

const onLoadProducts = (callback?: any) => ({
  type: GET_PRODUCTS_ALL,
  callback,
})

const onAddProducts = (data: any, callback?: any) => ({
  type: ADD_PRODUCTS,
  data,
  callback,
})
const onUpdateProduct = (id: any, data: any, callback?: any) => ({
  type: UPDATE_PRODUCT,
  id,
  data,
  callback,
})
const onDeleteProducts = (ids: any, callback?: any) => ({
  type: DELETE_PRODUCTS,
  ids,
  callback,
})
const onDeleteProduct = (id: any, callback?: any) => ({
  type: DELETE_PRODUCT,
  id,
  callback,
})

const Actions = {
  reset,
  onLoadProducts,
  onAddProducts,
  onDeleteProduct,
  onDeleteProducts,
  onUpdateProduct,
}

export default Actions
