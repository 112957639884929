import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const SecuredRoute = (props: any) => {
  const { currentUser } = useContext(AuthContext);
  if (!currentUser) {
    return (
      <Redirect
        to={{
          pathname: "/admin/login",
        }}
      />
    );
  }

  return <Route {...props} />;
};

export default SecuredRoute;
