import { Button, Grid, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
// import ImageItem from "../../../components/image/ImageItem";
import CustomizedDialog from "../../../components/custom-dialog/CustomizedDialog";
import { useHistory, useLocation } from "react-router";
import ImageInfo from "./add-images/ImageInfo";
import { Link } from "react-router-dom";
import qs from "qs";
import BotanicalGallery from "../../../components/reusable/gallery/BotanicalGallery";

function AdminGallery(props: {
  galleryList: any;
  onDeleteImage: (id: string) => void;
  handleAddImagesToGallery: (values: any, callback: any) => void;
  handleUpdateImageOnGallery: (id: any, values: any, callback: any) => void;
}) {
  const {
    galleryList,
    onDeleteImage,
    handleAddImagesToGallery,
    handleUpdateImageOnGallery,
  } = props;
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [formValues, setFormValues] = useState([{}] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isEditImage, setIsEditImage] = useState(false);
  const [updatedImage, setUpdatedImage] = useState({} as any);

  const setFormDetail = (index: any, values: any) => {
    setFormValues((prev: any) => {
      const newData = [...prev];
      newData[index] = values;
      return newData;
    });
  };
  const history = useHistory();
  const location = useLocation();
  const categories = useMemo(() => {
    const newCategories = {} as any;
    galleryList?.forEach((item: any) => {
      if (!newCategories[item.category]) newCategories[item.category] = 0;
      newCategories[item.category] = newCategories[item.category] + 1;
    });
    return newCategories;
  }, [galleryList]);
  const handleDelete = (event: any, id: any) => {
    onDeleteImage(id);
  };
  const requestImageBody = (value: any, index: number) => {
    const {
      name,
      botanicalName,
      hi,
      tn,
      s3_image_url,
      category = "Butterfly",
    } = value;
    console.log("testing", value);
    const error = {} as any;
    if (!s3_image_url) {
      error.s3_image_url = "Required";
    }
    if (!botanicalName) {
      error.botanicalName = "Required";
    }
    if (!name) {
      error.name = "Required";
    }
    if (!category) {
      error.category = "Required";
    }
    setErrors((prev: any) => {
      if (Object.keys(error).length > 0) {
        return [...prev, error];
      }
      return prev;
    });
    return {
      category,
      name,
      botanicalName,
      languages: [
        { language: "tn", name: tn },
        { language: "hi", name: hi },
      ],
      // languages: [en, tn],
      image: s3_image_url,
    };
  };
  const currentCategory =
    qs.parse(location?.search?.slice(1) || "")?.type || "";
  const handleSubmit = () => {
    setIsLoading(true);
    const requestFormValues = formValues.map(requestImageBody);
    if (!errors.length)
      handleAddImagesToGallery(requestFormValues, () => {
        setIsLoading(false);
        setShowCreateCategory(false);
      });
  };
  const handleUpdate = () => {
    setIsLoading(true);
    const requestFormValues = requestImageBody(updatedImage, 0);
    if (!errors.length)
      handleUpdateImageOnGallery(updatedImage._id, requestFormValues, () => {
        setIsLoading(false);
        setIsEditImage(false);
      });
  };
  return (
    <div
      style={{
        // margin: 'auto',
        display: "flex",
        justifyContent: "center",
        marginTop: "6rem",
        padding: "2rem",
      }}
    >
      <Grid
        container
        spacing={4}
        style={{
          maxWidth: "1366px",
        }}
      >
        <Grid
          sm={12}
          md={3}
          // style={{
          //   borderRight: "1px solid grey",
          //   minHeight: "calc(100vh - 10rem)",
          // }}
        >
          <Button color="secondary" onClick={() => setShowCreateCategory(true)}>
            + Category
          </Button>
          <div>
            <Link to={`/admin/gallery`}>All</Link>
            <br />
            {Object.keys(categories).map((key) => {
              return (
                <>
                  <Link
                    to={`/admin/gallery?type=${key}`}
                  >{`${key}(${categories[key]})`}</Link>
                  <br />
                </>
              );
            })}
          </div>
        </Grid>
        <Grid
          sm={12}
          md={9}
          style={{
            borderLeft: "1px solid grey",
            minHeight: "calc(100vh - 10rem)",
          }}
          item
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  history.push("/admin/gallery/upload"+history.location.search);
                }}
                color="success"
                variant="outlined"
              >
                Add Image
              </Button>
            </Grid>
            {/* {galleryList
              ?.filter((item: any) => {
                if (!currentCategory) return true;
                return item.category === currentCategory;
              })
              .map((item: any) => (
                <ImageItem
                  key={item._id}
                  onDelete={(event: any) => handleDelete(event, item._id)}
                  name={item.name}
                  image={item.image}
                  onEdit={() => {
                    setIsEditImage(true);
                    setUpdatedImage(item);
                  }}
                />
              ))} */}
              <BotanicalGallery imageList={galleryList?.map((item: any) => {
                return {
                  id: item._id,
                  name: item.name,
                  label: item.name,
                  imageData: item.image,
                  category: item.category,
                  botanicalName: item.botanicalName,
                }
              })?.filter((item: any) => {
                if (!currentCategory) return true;
                return item.category === currentCategory;
              }) as any}
              onEdit={(event: any, item: any) => {
                setIsEditImage(true);
                setUpdatedImage(galleryList?.find((item1: any) => item.id === item1._id));
              }}
              onDelete={(event: any, item: any) => handleDelete(event, item.id)}
              />
          </Grid>
        </Grid>
      </Grid>
      <CustomizedDialog
        title="Enter One category info"
        isOpen={showCreateCategory}
        onClose={() => setShowCreateCategory(false)}
        onSubmit={handleSubmit}
        loading={isLoading}
      >
        <TextField
          fullWidth
          required={true}
          color={"success"}
          id={"newCategory"}
          name={"newCategory"}
          label={"Enter New Category"}
          variant="standard"
          value={newCategory}
          onChange={(event: any) => setNewCategory(event.target.value)}
        />
        <ImageInfo
          setFormDetail={(data: any) => setFormDetail(0, data)}
          currentValue={formValues[0]}
          index={0}
          onDelete={formValues.length > 1 ? handleDelete : undefined}
          categories={[...Object.keys(categories), newCategory]}
        />
      </CustomizedDialog>
      <CustomizedDialog
        title="Edit"
        isOpen={isEditImage}
        onClose={() => {
          setIsEditImage(false);
        }}
        onSubmit={handleUpdate}
        loading={isLoading}
      >
        {console.log("testing", isEditImage)}
        <ImageInfo
          setFormDetail={(data: any) => {
            setUpdatedImage(data);
          }}
          currentValue={{
            ...(updatedImage || {}),
            s3_image_url: updatedImage?.image,
          }}
          index={0}
          // onDelete={formValues.length > 1 ? handleDelete : undefined}
          categories={[...Object.keys(categories)]}
        />
      </CustomizedDialog>
    </div>
  );
}

export default AdminGallery;
