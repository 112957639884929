import "./GalleryPage.scss";
import LandingPage from "../../components/landingPage/LandingPage";
import BirdsImageSrc from "../../media/images/animals/Birds.jpg";
import GalleryPageWired from "./GalleryPageWired";
import { Box } from "@mui/material";
import CategoryTabs from "../../components/category-tabs/CategoryTabs";
import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

const GalleryPage = () => {
  const [categories, setCategories] = useState<any>([]);
  const getData = () => {
    var arr: any[] = [];
    onSnapshot(collection(db, "categories"), (snapshot) => {
      snapshot.forEach((doc) => {
        arr.push({ id: doc.id, label: doc.data().name });
      });
      setCategories(arr);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="gallery-page">
      <LandingPage backGroundImage={BirdsImageSrc} isFullHeight={false}>
        <div className="page-text-wrapper"></div>
      </LandingPage>
      <Box
        justifyContent="center"
        justifyItems="center"
        flex={1}
        display="flex"
        paddingTop={2}
      >
        <Box
          justifyContent="center"
          display="flex"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <CategoryTabs categories={categories} orientation="horizontal" />
        </Box>
        {/* <BotanicalGallery
          showCategory={true}
          categoryAlign="center"
          imageList={
            galleryList?.map((item: any) => {
              return {
                id: item._id,
                name: item.name,
                botanicalName: item.botanicalName,
                label: item.name,
                imageData: item.image,
                category: item.category,
              };
            }) || []
          }
        /> */}
      </Box>
    </div>
  );
};

export default GalleryPageWired(GalleryPage);
