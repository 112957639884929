import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './translations/english'
import tn from './translations/tamil'
import hi from './translations/hindi'

const resources = {
  en: { translation: en },
  tn: { translation: tn },
  hi: { translation: hi },
}

const selectedLanguage = window.localStorage.getItem('lang') || 'en'

i18n.use(initReactI18next).init({
  resources,
  lng: selectedLanguage,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'en',
})

export default i18n
