import React from 'react'
import Grid from '@mui/material/Grid'
import './ImageWithPara.scss'

interface ImageWithParaProps {
  imageSrc: string
  altText: string
  renderImageText?: () => any
  renderRightSideData: () => any
  className?: string
  position?: 'left' | 'right'
}

const ImageWithPara = (props: ImageWithParaProps) => {
  const { imageSrc, altText, renderImageText, renderRightSideData, className, position='left' } = props

  return <div className={`image-with-para-container ${className ? className : ''}`}>
    <Grid container alignItems="center" spacing={3} direction={position === 'left' ? "row-reverse": 'row'}>
      <Grid item xs={12} sm={7} md={8} className="paragraph-grid">
        {renderRightSideData()}
      </Grid>
      <Grid item xs={12} sm={5} md={4} className="image-grid">
        <img className="image-src" src={imageSrc} alt={altText} />
        <div className="image-text">
          {renderImageText && renderImageText()}
        </div>
      </Grid>
    </Grid>
  </div>

}

export default ImageWithPara
