import { useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { db, storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { v4 as uuid } from "uuid";

interface Props {
  onClose: any;
  categories: any;
  singleImageData?: any;
}

const AddImageDialog = ({
  singleImageData,
  onClose,
  categories,
}: Props): JSX.Element => {
  const [imageUrl, setImageUrl] = useState<null | string>(
    singleImageData ? singleImageData.imageUrl : null
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(
    singleImageData ? singleImageData.categoryId : ""
  );
  // const [value, setValue] = useState<any>(
  //   singleImageData ? singleImageData.categoryName : null
  // );
  // const [selectedCategory, setSelectedCategory] = useState<any>("");
  const [imageName, setImageName] = useState<string>(
    singleImageData ? singleImageData.name : ""
  );
  const [botanicalName, setBotanicalName] = useState<string>(
    singleImageData ? singleImageData.botanicalName : ""
  );
  const [tamilName, setTamilName] = useState<string>(
    singleImageData ? singleImageData.tamilName : ""
  );
  const [hindiName, setHindiName] = useState<string>(
    singleImageData ? singleImageData.hindiName : ""
  );

  const handleImage = (event: any) => {
    const selectedImage = event.target.files[0];

    try {
      const storageRef = ref(storage, selectedImage.name);
      const uploadTask = uploadBytesResumable(storageRef, selectedImage);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setIsUploading(true);
          console.log(
            "uploading ::: ",
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err: any) => {
          console.log(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImageUrl(downloadURL);
            setIsUploading(false);
          });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const handleAddImage = async () => {
    const categoryId = selectedCategory;
    console.log("selected category", selectedCategory);
    try {
      const id = singleImageData
        ? singleImageData.id
        : uuid().split("-").join("");
      if (imageUrl && selectedCategory && imageName) {
        await setDoc(
          doc(db, "categories", categoryId, "images", id),
          {
            imageUrl: imageUrl,
            name: imageName,
            botanicalName: botanicalName,
            tamilName: tamilName,
            hindiName: hindiName,
            date: Timestamp.now(),
          },
          { merge: true }
        );
      }
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Add Category
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme: any) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ px: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            {isUploading ? (
              <div className="upload-loader">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <>
                {imageUrl ? (
                  <div className="uploaded-image">
                    <span onClick={() => setImageUrl(null)}>🗙</span>
                    <img src={imageUrl} alt="" />
                  </div>
                ) : (
                  <label>
                    <Box className="upload-img-btn">
                      <Button
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={handleImage}
                        />
                        <PhotoCameraIcon />
                      </Button>
                    </Box>
                  </label>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={9}>
            <Typography fontSize={20} fontWeight={700} sx={{ pb: 2 }}>
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="category-label">Select a Category</InputLabel>
                  <Select
                    labelId="category-label"
                    id="category"
                    value={selectedCategory}
                    label="Select a Category"
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                    }}
                  >
                    {categories.map((category: any, i: string) => (
                      <MenuItem value={category.id} key={i}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  value={imageName}
                  onChange={(e: any) => setImageName(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Botanical Name"
                  value={botanicalName}
                  onChange={(e: any) => setBotanicalName(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Typography fontSize={20} fontWeight={700} sx={{ pt: 3, pb: 2 }}>
              Other Languages
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Name in Tamil"
                  value={tamilName}
                  onChange={(e: any) => setTamilName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Name in Hindi"
                  value={hindiName}
                  onChange={(e: any) => setHindiName(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddImage}
          disabled={!imageUrl && !selectedCategory && !imageName}
        >
          {singleImageData ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </>
  );
};

export default AddImageDialog;
