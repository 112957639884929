const tn = {
  'welcome.note': 'கிளவுட் ஃபாரஸ்ட் பூங்காவிற்கு வரவேற்கிறோம்',
  'navigation.about': 'பற்றி',
  'navigation.gallery': 'கலை காட்சி கூடம்',
  'butterfly.glassy.tiger': 'கண்ணடி விரியன்',
  'name': 'பெயர்',
  'all': 'அனைத்தும்',
  'bird': 'பறவை',
  'birds': 'பறவைகள்',
  'fish': 'மீன்',
  'fishes': 'மீன்கள்',
  'botanical.name': 'தாவரவியல் பெயர்',
  'butterfly': 'பட்டாம்பூச்சி',
  'butterfly.parantica.aglea': 'பாராண்டிகா அக்லியா',
}

export default tn
