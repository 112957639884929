import Butterfly from './images/animals/Butterfly.jpg'
import Butterfly2 from './images/animals/Butterfly-2.jpg'
import Flower from './images/animals/Flower1.jpg'
import DomesticPets from './images/animals/PigRat02.jpg'
import FruitGarden from './images/animals/fruits.jpg'
import PlayWithPets from './images/animals/playWithPets.jpg'
import FishSPA from './images/animals/fishSPA.jpg'
import PlayZone from './images/animals/KidsPlayZone.jpg'
import Birds from './images/animals/Birds.jpg'
import SuspensionBridge from './images/animals/SuspensionBridge.jpg'
import BambooForest from './images/animals/BambooForest.jpg'
import WatterFall from './images/animals/WatterFall.jpg'
import CherryTree from './images/fruits/CherryTree.jpg'
import GlassyTiger from './images/butterflies/GlassyTiger.jpeg'
import BlueMormon from './images/butterflies/BlueMormon.jpeg'
import CommonBluebottle from './images/butterflies/CommonBluebottle.jpeg'
import CommonRose from './images/butterflies/CommonRose.jpeg'
import CommonSailer from './images/butterflies/CommonSailer.jpeg'
import CrimsonRose from './images/butterflies/CrimsonRose.jpeg'
import DarkBlueTiger from './images/butterflies/DarkBlueTiger.jpeg'
import TailedGay from './images/butterflies/TailedGay.jpeg'
import Amla from './images/fruit-orchid/Amla(goose-berry).jpeg'
import Ber from './images/fruit-orchid/Ber.jpeg'
import CustardApple from './images/fruit-orchid/CustardApple.jpeg'
import Durian from './images/fruit-orchid/Durian.jpeg'
import FigFruit from './images/fruit-orchid/FigFruit.jpeg'
import Grapes from './images/fruit-orchid/Grapes.jpeg'
import Guava from './images/fruit-orchid/Guava.jpeg'
import Jackfruit from './images/fruit-orchid/Jackfruit.jpeg'
import JamunFruit from './images/fruit-orchid/JamunFruit.jpeg'
import Litchi from './images/fruit-orchid/Litchi.jpeg'
import Mango from './images/fruit-orchid/Mango.jpeg'
import Mulberry from './images/fruit-orchid/Mulberry.jpeg'
import Papaya from './images/fruit-orchid/Papaya.jpeg'
import PassionFruit from './images/fruit-orchid/PassionFruit.jpeg'
import Pomegranate from './images/fruit-orchid/Pomegranate.jpeg'
import Rambutan from './images/fruit-orchid/Rambutan.jpeg'
import RedBanana from './images/fruit-orchid/RedBanana.jpeg'
import Sapota from './images/fruit-orchid/sapota.jpeg'
import StarFruit from './images/fruit-orchid/StarFruit.jpeg'
import SweetLemon from './images/fruit-orchid/SweetLemon.jpeg'
import WaterApple from './images/fruit-orchid/WaterApple.jpeg'
import WestindiesCherry from './images/fruit-orchid/WestindiesCherry.jpeg'
import SweetOrange from './images/fruit-orchid/SweetOrange.jpeg'
import CommonCrow from './images/butterflies/CommonCrow.jpeg'
import CommonFiveRing from './images/butterflies/CommonFiveRing.jpeg'
import CommonIndianCrow from './images/butterflies/CommonIndianCrow.jpeg'
import CommonPierrot from './images/butterflies/CommonPierrot.jpeg'
import CommonLascar from './images/butterflies/CommonLascar.jpeg'
import GreatOrangeTip from './images/butterflies/GreatOrangeTip.jpeg'
import LimeButterfly from './images/butterflies/LimeButterfly.jpeg'
import MalabarTreeNymph from './images/butterflies/MalabarTreeNymph.jpeg'
import PaintedLady from './images/butterflies/PaintedLady.jpeg'
import PeacockPansy from './images/butterflies/PeacockPansy.jpeg'
import Pioneer from './images/butterflies/Pioneer.jpeg'
import PlainTiger from './images/butterflies/PlainTiger.jpeg'
import RedPierrot from './images/butterflies/RedPierrot.jpeg'
import StripedTiger from './images/butterflies/StripedTiger.jpeg'
import TamilYeoman from './images/butterflies/TamilYeoman.jpeg'
import Tawnycoster from './images/butterflies/Tawnycoster.jpeg'
import Yamfly from './images/butterflies/Tawnycoster.jpeg'
import ArtificialDecorative from './images/others/ArtificialDecorative.jpg'
import PersonalizedGarden from './images/others/PersonalizedGarden.jpg'
import SmartGardening from './images/others/SmartGardening.jpg'
import TreeHouse from './images/others/TreeHouse.jpg'
import TreeHouseWindow from './images/others/TreeHouseWindow.jpg'
import GuestHouse from './images/others/GuestHouse.jpg'

export const fruits = {
  Amla,
  SweetLemon,
  WestindiesCherry,
  WaterApple,
  SweetOrange,
  Ber,
  CustardApple,
  Durian,
  FigFruit,
  Grapes,
  Guava,
  Jackfruit,
  JamunFruit,
  Litchi,
  Mango,
  Mulberry,
  RedBanana,
  PassionFruit,
  Rambutan,
  Papaya,
  Pomegranate,
  Sapota,
  StarFruit,
}

export const Butterflies = {
  BlueMormon,
  Butterfly,
  Butterfly2,
  CommonBluebottle,
  CommonCrow,
  CommonFiveRing,
  CommonIndianCrow,
  CommonLascar,
  CommonPierrot,
  CommonRose,
  CommonSailer,
  CrimsonRose,
  DarkBlueTiger,
  GlassyTiger,
  GreatOrangeTip,
  LimeButterfly,
  MalabarTreeNymph,
  PaintedLady,
  PeacockPansy,
  Pioneer,
  PlainTiger,
  RedPierrot,
  StripedTiger,
  TailedGay,
  TamilYeoman,
  Tawnycoster,
  Yamfly,
}

export const others = {
  ArtificialDecorative,
  PersonalizedGarden,
  SmartGardening,
  TreeHouse,
  TreeHouseWindow,
  GuestHouse,
}

export const Images = {
  Flower,
  DomesticPets,
  FruitGarden,
  PlayWithPets,
  FishSPA,
  PlayZone,
  Birds,
  SuspensionBridge,
  BambooForest,
  WatterFall,
  CherryTree,
  ...Butterflies,
  ...fruits,
  ...others,
}

export default Images