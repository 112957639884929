import { WhatsApp } from "@mui/icons-material"
import { IconButton } from "@mui/material"

const FloadingAction = () => {
  return <div style={{
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
    zIndex: 2,
  }}>
    <IconButton onClick={() => {
      window.open('https://wa.me/917010050711?text=Hi%2C+I+Want+to+know+more+about+CloudForesPark', '_blank')
    }}>
      <WhatsApp style={{
        color: 'green',
        backgroundColor: 'white',
        borderRadius: '50%',
        fontSize: '3rem',
      }}/>
    </IconButton>
  </div>
}
export default FloadingAction
