import { Grid } from "@mui/material"
import TopicConainer from "../../../components/topicContainer/TopicContainer"
import Images from "../../../media/images"

const items = [
  {
    label: 'Flower Garden',
    description: 'This place will spread the aroma of flowers everywhere around you',
    imageSrc: Images.Flower,
    size: {
      md: 12,
    },
    url: '/gallery'
  },
  {
    label: 'Tropical fruits',
    description: 'Here is the place where we can smell and touch the  sweetness of real fruits',
    imageSrc: Images.CherryTree,
    size: {
      md: 12,
    },
    url: '/gallery'
  },
  {
    label: 'Domestic Pets',
    description: 'walk along with the domestic pets and enjoy the experience',
    imageSrc: Images.DomesticPets,
    size: {
      md: 12,
    },
    url: '/gallery'
  },
  {
    label: 'Butterfly Garden',
    description: 'We created a small flower garden inside where we can see a lot of 15+ types of butterfly (Best time to view butterfly on summer time)',
    imageSrc: Images.Butterfly2,
    size: {
      md: 6,
    },
    url: '/gallery?type=butterfly'
  },
  {
    label: 'Play with pets',
    description: 'You are allowed to feed, touch and even hold it on your hands & shoulders and take photograph',
    imageSrc: Images.PlayWithPets,
    size: {
      md: 6,
    },
    url: '/gallery'
  },
  {
    label: 'Play zone',
    description: 'play area is here with lot of 50+ games for both kids and adults',
    imageSrc: Images.PlayZone,
    size: {
      md: 6,
    },
    url: '/gallery'
  },
  {
    label: 'Fish Spa',
    description: 'fish spa is available for all in single pool with free of cost',
    imageSrc: Images.FishSPA,
    size: {
      md: 6,
    },
    url: '/gallery'
  },
  {
    label: 'Suspension bridge',
    description: "It’s a kind of place where we can see the sunset view and on the top you can see the over all view around 360 degree",
    imageSrc: Images.SuspensionBridge,
    size: {
      md: 4,
    },
    // url: '/gallery'
  },
  {
    label: 'Bamboo forest',
    description: "It’s a small place where we can go mini trekking around 500m",
    imageSrc: Images.BambooForest,
    size: {
      md: 4,
    },
    // url: '/gallery'
  },
  {
    label: 'Waterfall view',
    description: 'walk along with the domestic pets and enjoy the experience',
    imageSrc: Images.WatterFall,
    size: {
      md: 4,
    },
    // url: '/gallery'
  },
  // {
  //   label: 'Comming Soon',
  //   description: 'walk along with the domestic pets and enjoy the experience',
  //   imageSrc: Images.WatterFall,
  //   size: {
  //     md: 4,
  //   },
  //   url: '/gallery'
  // },
]

const WhatYouSee = () => {
  return <div style={{
    // marginBottom: '2rem',
  }}>
    <Grid container>
      {
        items.map((item: any, index: number) => {
          return <Grid key={`wus-${index}`} item xs={12} md={item?.size?.md || 12}>
            <TopicConainer
              title={item.label}
              description={item.description}
              imageSrc={item.imageSrc}
              url={item.url}
            />
          </Grid>
        })
      }
    </Grid>
  </div>
}

export default WhatYouSee
