import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, Grid, IconButton, TextField } from "@mui/material";

import "./ImageInfo.scss";
import { Delete } from "@mui/icons-material";
const fields = [
  {
    name: "category",
    label: "Category",
    type: "select",
    required: true,
    span: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "name",
    label: "Name",
    type: "text",
    required: true,
    span: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "botanicalName",
    label: "Botanical Name",
    type: "text",
    required: true,
    span: {
      xs: 12,
      md: 6,
    },
  },
];

const languages = [
  {
    name: "tn",
    label: "Tamil",
    span: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "hi",
    label: "Hindi",
    span: {
      xs: 12,
      md: 6,
    },
  },
];
const renderInputFields = (
  field: any,
  values: any,
  handleUpdate: any,
  categories: any
) => {
  if (field.type === "text") {
    return (
      <TextField
        fullWidth
        required={field.required}
        color={"success"}
        id={field.name}
        name={field.name}
        label={field.label}
        variant="standard"
        value={values[field.name] || ""}
        onChange={(event: any) => handleUpdate(field.name, event.target.value)}
      />
    );
  }
  if (field.type === "select") {
    return (
      <Autocomplete
        fullWidth
        id={field.name}
        value={values[field.name] || null}
        onChange={(event: any, value: any) =>
          handleUpdate(field.name, value || event.target.value)
        }
        options={categories || []}
        sx={{ width: 300 }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            color={"success"}
            variant="standard"
            name={field.name}
            label={field.label}
            required={field.required}
          />
        )}
      />
    );
  }
  return <></>;
};
function ImageInfo(props: any) {
  const { setFormDetail, currentValue, onDelete, index, categories } = props;
  const [values, setValues] = useState((currentValue || {}) as any);
  // const [categories, setCategories] = useState(initCategoryList);
  useMemo(() => {
    const newValues = {} as any;
    values?.languages?.forEach((language: any) => {
      newValues[language.language] = language.name;
    });
    const keys = Object.keys(newValues);
    if (keys?.find((key: string) => !values[key] && newValues[key])) {
      setValues((prev: any) => {
        return { ...prev, ...newValues };
      });
    }
    return newValues;
  }, [values]);

  const handleUpdate = (key: any, value: any) => {
    setValues((prev: any) => {
      return { ...prev, [key]: value };
    });
  };
  useEffect(() => {
    if (currentValue && values !== currentValue) setFormDetail(values);
  }, [currentValue, setFormDetail, values]);
  return (
    <div>
      <Grid container className="imge-info-form-container">
        <Grid xs={12} md={3}>
          <img
            className="preview-image"
            src={values?.s3_image_url || ""}
            alt={values?.s3_image_url || "Preview Image"}
          />
        </Grid>
        <Grid item xs={12} md={8} spacing={3} className="form-fields">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} p={0} className="pt-0">
              <TextField
                fullWidth
                color={"success"}
                id="s3-image-url"
                label="S3 Image URL"
                variant="standard"
                value={values.s3_image_url || ""}
                onChange={(event: any) =>
                  handleUpdate("s3_image_url", event.target.value)
                }
              />
            </Grid>
            <Grid item sm={12} className="pt-0">
              <h3>Basic Details</h3>
            </Grid>
            {fields.map((field: any, index1: any) => {
              return (
                <Grid item md={6} key={index1} className="pt-0">
                  {renderInputFields(field, values, handleUpdate, categories)}
                </Grid>
              );
            })}
            <Grid item sm={12} className="pt-0">
              <h3>Other Languages</h3>
            </Grid>
            {languages.map((field: any) => {
              return (
                <Grid item md={6} className="pt-0">
                  <TextField
                    fullWidth
                    color={"success"}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    variant="standard"
                    value={values[field.name] || ""}
                    onChange={(event: any) =>
                      handleUpdate(field.name, event.target.value)
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid xs={1}>
          <IconButton disabled={!onDelete} onClick={() => onDelete(index)}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default ImageInfo;
