import React from 'react'
import { Grid } from '@mui/material'
import './OurAnimals.scss'
import Counter from '../../components/counter/Counter'
import { Fruits, Bird, Butterfly, Fish, Flower, Animal } from '../../media/svgs'

const types = [
  { name: 'Flowers', count: 2001 },
  { name: 'Trees', count: 350 },
  { name: 'Birds', count: 32 },
  { name: 'Animals', count: 109 },
  // { name: 'Games', count: 10 },
]

const animalTypeList = [
  { name: 'Birds' , icon: Bird},
  { name: 'Pets' , icon: Animal},
  { name: 'Fruits' , icon: Fruits},
  { name: 'Butterflies' , icon: Butterfly},
  { name: 'Fishes' , icon: Fish},
  { name: 'Flowers' , icon: Flower},
  // { name: 'Trees' , icon: <i className="fa fa-tree"></i>},
]

const OurAnimals = () => {
  return <div className="our-animals-container">
    <Grid container spacing={2} justifyContent="center" style={{
    maxWidth: 1366,
    padding: '2rem',
  }}>
      <Grid item xs={12} sm={12} md={5}>
        <h1><b>Awesome Animals </b>in our Park</h1>

        We have lot of nature around, such as flowers, trees, animals, birds and much more. This is the place where we can feel the aroma of flowers, sweetness of fruits , the sense of cold and the chirping of birds.we can also have a play area for both kids and adults as well.
      </Grid>
      <Grid item xs={12} sm={12} md={7} alignItems="end" justifyContent="end">
        <Grid container spacing={3}>
          {types.sort((a: any, b: any) => -(a.count - b.count))?.map((type: any, index: number) => {
            return <Grid key={`animals-count-${index}`} item className="items-count" xs={6} sm={3} md={3} lg={3}>
              <div>
                <div>{type.name}</div>
                <Counter count={type.count} />
              </div>
            </Grid>
          })}
        </Grid>
      </Grid>
    </Grid>
    <Grid container spacing={5} className="animals-lists" style={{
    maxWidth: 1366,
    padding: '2rem',
  }}>
      {animalTypeList?.map((type: any, index: number) => {
        const { icon: Icon } = type
        return <Grid item
        alignItems="center"
        justifyContent="end"
        key={index}
        className="animals-list-item"
        xs={6}
        sm={4}
        md={2}
        lg={2}
        >
          <div className={`index-${index%3}`}>
            <div className="icon">
              <Icon width="3rem" height="3rem" />
            </div>
            <div>{type.name}</div>
          </div>
        </Grid>
      })}
    </Grid>
  </div>
}

export default OurAnimals
