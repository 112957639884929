// import UnderWork from '../errors/UnderWork'
import './About.scss'
const About = () => {
    return <div className="about-us">
        <div>
            <h1>About Us</h1>
            This place is locate at we  created this park to protect the animals that live inside and outside of the land. This place

            provides you a peaceful mind and seclusion that are important for human health and well being.

            It is an open area ,often found with trees, benches etc..
            There is also a lot of nature around, such as flowers, trees, animals, birds and much more.
            This is the place where we can feel the aroma of flowers, sweetness of fruits ,
            the sense of cold and the chirping of birds.we can also have a play area for both kids and adults as well.
        </div>
    </div>
}

export default About
