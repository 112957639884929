import { Chip } from "@mui/material"
import { Box } from "@mui/system"
import ImageWithPara from "../../components/imageWithPara/ImageWithPara"
import Images from "../../media/images"
import './OurServices.scss'


const services = [
  {
    title: 'Dream House',
    description: 'We do out of the box concept for private guest house as per your requirement.',
    items: [
      { label: 'Guest House' },
      { label: 'Tree House' },
      { label: 'Bamboo House' },
      { label: 'Swimmig Pool' },
    ],
    imageSrc: Images.GuestHouse,
  },
  {
    title: 'Personalized Garden',
    description: 'We under take all kinds of private 100% Personalized Garden based on your requirement.',
    items: [
      { label: 'Flower Garden' },
      { label: 'Fruit Garden' },
      { label: 'Herbs Garden' },
      { label: 'Pets Garden (Private zoo)' },
      { label: 'Butterfly Garden' },
      { label: 'Artificial Fountain' },
      { label: 'Game Zone' },
    ],
    imageSrc: Images.PersonalizedGarden,
  },
  {
    title: 'Artificial Decorative Gardening',
    description: 'We will transform your normal gared by cutting & shaping your plants to look very clean and attractive with full refreshment.',
    items: [
      { label: 'Water fountain' },
      { label: 'Grass Placement' },
      { label: 'Morden Path Way' },
      { label: 'Smart Lighting' },
      { label: 'Plants shaping' },
    ],
    imageSrc: Images.ArtificialDecorative,
  },
  {
    title: 'Smart Gardening',
    description: 'We will make the normal garden into fully automatic maintain free with smart technology. You can control the daily task through smart phone like watering, lighting etc..',
    items: [
      { label: 'Watering on schedule time' },
      { label: 'Drip Irrigation methods' },
      { label: 'Sprinkler Irrigation' },
      { label: 'Automatic Lighting' },
      { label: 'Live preview video' },
    ],
    imageSrc: Images.SmartGardening,
  },
]

const ServiceDetails = (props: any) => {
  const { title, description, lists } = props
  return <div className="right-text-container">
  <h1>{title}</h1>
  <p>
    {description}
  </p>
  <div>
    {lists?.map((item: any) => <Chip variant="filled" color="default" style={{ marginBottom: '.5rem', marginRight: '.5rem', color: 'black' }} label={item.label} />)}
  </div>
</div>
}

const OurServices = () => {
  return <div className="our-services-container">
    <Box justifyContent="center" color="black">
      {services.map((item: any, index: number) => {
        return <ImageWithPara
        // renderImageText={() => <ServiceDetails title="Title" />}
        renderRightSideData={() => <ServiceDetails
          description={item.description}
          title={item.title}
          lists={item.items}
        />}
        // position={index % 2 === 0 ? 'left' : 'right'}
        imageSrc={item.imageSrc}
        altText={item.title}
      />
      })}
    </Box>
    {/* <Grid container className="member-container">
      <Grid item xs={12}>
        <h1>It's Cool to be</h1>
        <h1>Our Member</h1>
        <p>
          Zebra oto pikeperch yellow jack nibbler ronquil duckbill round stingray greenling eulachon: grunt sculpin.flounder bleak gouramie algae eater gurnard
        </p>
        <Link to="/tickets"><Button variant="outlined" color="warning">Become a Member</Button></Link>
      </Grid>
    </Grid> */}
  </div>
}

export default OurServices
