/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import qs from "qs";
import Actions from "../../../../redux/actions/product/gallery";
import { useEffect } from "react";

const AddImageFormWired = (WrappedComponent: any) => {
  const NewComponent = (props: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { galleryList } = useSelector(
      (state: any) => state?.products?.gallery || {}
    );

    useEffect(() => {
      dispatch(Actions.onLoadProducts());
    }, []);
    const handleAddImagesToGallery = (values: any) => {
      dispatch(
        Actions.onAddProducts(values, () => {
          history.push("/admin/gallery"+history.location.search);
        })
      );
    };

    return (
      <WrappedComponent
        {...props}
        galleryList={galleryList}
        handleAddImagesToGallery={handleAddImagesToGallery}
      />
    );
  };

  return NewComponent;
};

export default AddImageFormWired;
