import './LandingPage.scss'

const LandingPage = (props: {
  backGroundImage: string
  children: any
  isFullHeight?: boolean
}) => {
  const { backGroundImage, children, isFullHeight } = props
  return <div
    className={`landing-page-container ${isFullHeight ? 'full-height' : ''}`}
    style={{
      backgroundImage: `url(${backGroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <div className="page-description">
      {children}
    </div>
  </div>
}
export default LandingPage
